a.fancy-underline {
    position: relative;
    color: #000;
    text-decoration: none;
}

a.fancy-underline:hover {
    color: #e4a8fe;
}

a.fancy-underline::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 1.5px;
    bottom: 0;
    left: 0;
    background-color: #e4a8fe;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

a.fancy-underline:hover::before {
    transform: scaleX(1);
}
