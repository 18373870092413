.cover-heading {
    font-size: 18vw;
    line-height: 1;
}
  
span.heading {
    background: url(../../../images/profile.jpg);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}